import {
  IAddDriver,
  IListDriver,
  IUpdateDriver,
  IUpdateDriverState,
} from '../../types';
export const driverJSON = {
  addDriver: ({
    name,
    email,
    imageReader,
    phone,
    phoneCountry,
    qrImageReader,
    lipaNumber,
    lipaPhoneCountry,
    isFreelance,
  }: IAddDriver) => {
    let form = new FormData();
    form.append('name', name);
    form.append('email', email);
    form.append('image', imageReader);
    form.append('phone', phone);
    form.append('phoneCountry', phoneCountry);
    form.append('isFreelance', isFreelance);
    // if (Object.keys(qrImageReader).length) {
      form.append('qrImage', qrImageReader);
    // }
    if (lipaNumber) {
      form.append('lipaNumber', lipaNumber);
      form.append('lipaCountryCode', lipaPhoneCountry);
    }
    return form;
  },
  listDriver: ({
    limit,
    sortKey,
    sortOrder,
    pageNo,
    searchTerm,
    listType,
    isDeleted,
  }: IListDriver) => {
    return {
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      pageNo: pageNo,
      searchTerm: searchTerm,
      listType: listType,
      isDeleted: isDeleted,
    };
  },
  updateStatus: ({ active }: IUpdateDriverState) => {
    return { active: active };
  },
  updateDriver: ({
    name,
    email,
    imageReader,
    phone,
    phoneCountry,
    qrImageReader,
    lipaNumber,
    lipaCountryCode,
    isFreelance
  }: IUpdateDriver) => {
    let form = new FormData();
    form.append('name', name);
    form.append('email', email);
    form.append('phone', phone);
    form.append('phoneCountry', phoneCountry);
    form.append('qrImage', qrImageReader);
    form.append('image', imageReader);
     form.append('isFreelance', isFreelance);
    if (lipaNumber) {
      form.append('lipaNumber', lipaNumber);
      form.append('lipaCountryCode', lipaCountryCode);
    }
    return form;
  },
};
