import { Modal } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
const PlanVehicleDetails = (props: any) => {
  return (
    <>
      <Modal
        {...props}
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered "
        className="border-r10px"
        centered
        backdrop={props.loading ? 'static' : true}
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto mb-5">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-425px pt-lg-3">
            Plan for this {props.vehicle.name} has been generated
          </Modal.Title>
          <br />
        </Modal.Header>
      </Modal>
    </>
  );
};
export default PlanVehicleDetails;
