// API END Points
import * as constants from '../utils/constants';
/**---------------Authentication------------------------------ */
export const LOGIN = 'admin/login' + ' ' + constants.POST_RAW;
export const LOGOUT = 'admin/logout' + ' ' + constants.POST_RAW;
export const SIDEBAR = 'admin/dashboard/stats' + ' ' + constants.GET;
export const DASHBOARD = 'admin/dashboard' + ' ' + constants.GET_URL_PARAMS;
export const AUTH = {
  RESEND: 'admin/otp/resend' + ' ' + constants.PATCH,
  VERIFY: 'admin/otp/verify' + ' ' + constants.POST_URL_ENCODED,
  FORGOTPASSWORD: 'admin/password/forgot' + ' ' + constants.POST_URL_ENCODED,
  VERIFYPASSWORD:
    'admin/password/code/verify' + ' ' + constants.POST_URL_ENCODED,
  RESETPASSWORD: 'admin/password/reset' + ' ' + constants.POST_URL_ENCODED,
  DASHBOARD: 'admin/dashboard' + ' ' + constants.GET,
  checkPassword: 'admin/check-password' + ' ' + constants.POST_RAW,
  notification: 'admin/notifications' + ' ' + constants.GET_URL_PARAMS,
  readNotifications: 'admin/notifications' + ' ' + constants.PATCH,
  notificationCount: 'admin/notifications-count' + ' ' + constants.GET,
};
export const master = {
  // Primary category
  addCategory: 'business/categories' + ' ' + constants.POST_FORM,
  editCategory: 'business/categories' + ' ' + constants.MULTI_PART_ID_PATCH,
  deleteCategory: 'business/categories' + ' ' + constants.DELETE_ID_PARAMS,
  categoryList: 'business/categories' + ' ' + constants.GET_URL_PARAMS,
  // Sub category
  addSubCategory: 'business/categories/sub' + ' ' + constants.POST_FORM,
  editSubCategory:
    'business/categories/sub' + ' ' + constants.MULTI_PART_ID_PATCH,
  deleteSubCategory:
    'business/categories/sub' + ' ' + constants.MULTI_PART_ID_DELETE,
  // Group category
  addGroupCategory: 'business/categories/group' + ' ' + constants.POST_FORM,
  editGroupCategory:
    'business/categories/group' + ' ' + constants.MULTI_PART_ID_PATCH,
  deleteGroupCategory:
    'business/categories/group' + ' ' + constants.MULTI_PART_ID_DELETE,
  // Business ceritificates
  addCertificate: 'business/config/certificate' + ' ' + constants.POST_FORM,
  listCertificate:
    'business/config/certificate' + ' ' + constants.GET_URL_PARAMS,
  editCertificate:
    'business/config/certificate' + ' ' + constants.MULTI_PART_ID_PATCH,
  deleteCertificate:
    'business/config/certificate' + ' ' + constants.DELETE_ID_PARAMS,
  // Business types
  addBusinessTypes: 'business/types' + ' ' + constants.POST_FORM,
  editBusinessTypes: 'business/types' + ' ' + constants.MULTI_PART_ID_PATCH,
  deleteBusinessTypes: 'business/types' + ' ' + constants.DELETE_ID_PARAMS,
  listBusinessTypes: 'business/types' + ' ' + constants.GET_URL_PARAMS,
  // Brands
  addBrands: '_product/default/brands' + ' ' + constants.POST_FORM,
  editBrands: '_product/default/brands' + ' ' + constants.MULTI_PART_ID_PATCH,
  deleteBrands: '_product/default/brands' + ' ' + constants.DELETE_ID_PARAMS,
  listBrands: '_product/default/brands' + ' ' + constants.GET_URL_PARAMS,
  //Technical Details
  technicalCategories:
    '_product/default/technical-detail-fields/categories' + ' ' + constants.GET,
  technicalDetailFeild:
    '_product/default/technical-detail-fields/categories' +
    ' ' +
    constants.GET_ID_PARAMS,
  addTechnicalDetails:
    '_product/default/technical-detail-fields' + ' ' + constants.POST_RAW,
  editTechnicalDetails:
    '_product/default/technical-detail-fields' + ' ' + constants.PATCH_ID,
  deleteTechnicalDetails:
    '_product/default/technical-detail-fields' +
    ' ' +
    constants.DELETE_ID_PARAMS,
  //Product Variants
  variantTiles: '_product/default/variants' + ' ' + constants.GET_URL_PARAMS,
  addVariants: '_product/default/variants' + ' ' + constants.POST_RAW,
  editVariants: '_product/default/variants' + ' ' + constants.PATCH_ID,
  deleteVariants:
    '_product/default/variants' + ' ' + constants.DELETE_ID_PARAMS,
  deleteSubVariants:
    '_product/default/variants' + ' ' + constants.MULTI_PART_ID_DELETE,
  variantInfo: '_product/default/variants' + ' ' + constants.GET_URL_ID_PARAMS,
  addArea: 'business/loading-areas' + ' ' + constants.POST_FORM,
  listArea: 'business/loading-areas' + ' ' + constants.GET_URL_PARAMS,
  editArea:
    'business/loading-areas' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  info: 'business/loading-areas' + ' ' + constants.GET_ID_PARAMS,
  updateAreaStatus: 'business/loading-areas/status' + ' ' + constants.PATCH_ID,
  assignLoadingArea: 'business/assign-loading-areas' + ' ' + constants.POST_RAW,
  // roles and permission
  addRoles: 'config/role' + ' ' + constants.POST_RAW,
  getRoles: 'config/role' + ' ' + constants.GET_URL_PARAMS,
  getRoleDetails: 'config/role' + ' ' + constants.GET_ID_PARAMS,
  editDetails: 'config/role' + ' ' + constants.PATCH_ID,
  deleteRole: 'config/role' + ' ' + constants.DELETE_ID_PARAMS,
  // user management
  addUser: 'user-management' + ' ' + constants.POST_FORM,
  getUsers: 'user-management' + ' ' + constants.GET_URL_PARAMS,
  updateUserStatus: 'user-management/status' + ' ' + constants.PATCH_ID,
  deleteUser: 'user-management' + ' ' + constants.DELETE_ID_PARAMS,
  getUserDetails: 'user-management' + ' ' + constants.GET_ID_PARAMS,
  editUserDetails: 'user-management' + ' ' + constants.PATCH_FORM_ID,
};
export const seller = {
  kycDetails: 'business/list' + ' ' + constants.GET_URL_PARAMS,
  checkCategories:
    'business/check-categories' + ' ' + constants.GET_URL_ID_PARAMS,
  sellerProfile: 'business/profile' + ' ' + constants.GET_ID_PARAMS,
  kycStatusUpdate:
    'business/kyc/status' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  sellerProfileUpdate: 'business/profile' + ' ' + constants.MULTI_PART_ID_PATCH,
  profileUpdateLogs:
    'business/update-logs/profile' + ' ' + constants.GET_ID_PARAMS,
  listSeller: 'seller/manage-business/list' + ' ' + constants.GET_URL_PARAMS,
  updateSellerState:
    'seller/manage-business/account-state' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  addSeller: 'seller/manage-business/add' + ' ' + constants.POST_FORM,
  listSellerProduct:
    '_product/manage/business-products/list' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  listSellableProduct:
    '_product/manage/business-products/sellable' +
    ' ' +
    constants.GET_URL_PARAMS,
  sellableProductInfo:
    '_product/manage/business-products/info' + ' ' + constants.GET_ID_PARAMS,
  listInventory:
    '_product/manage/inventory/business/v2/list' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  listInventoryHistory:
    '_product/manage/inventory/business/transactions' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  listStockDetails:
    '_product/manage/inventory/business/in-stock' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  listOrder: '_product/manage/orders/list' + ' ' + constants.GET_URL_ID_PARAMS,
  listAdvertisement:
    '_product/manage/advertisements' + ' ' + constants.GET_URL_ID_PARAMS,
  listGoodsRequest:
    '_product/manage/shipments/list' + ' ' + constants.GET_URL_ID_PARAMS,
  payoutProfiles: 'business/payout-profiles' + ' ' + constants.GET_URL_PARAMS,
  payoutOrderList:
    '_product/manage/orders/payouts/business-orders' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  payoutInfo: '_product/manage/orders/payouts' + ' ' + constants.GET_ID_PARAMS,
  payoutList:
    '_product/manage/orders/payouts/business' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  payout:
    '_product/manage/orders/payouts/business' + ' ' + constants.POST_ID_PARAMS,
  orderSales:
    '_product/manage/orders/sales-report' + ' ' + constants.GET_URL_PARAMS,
  orderSalesReport:
    '_product/reports/order-sales' + ' ' + constants.GET_URL_PARAMS,
  orderSalesReportV2:
    '_product/reports/order-sales-v2' + ' ' + constants.GET_URL_PARAMS,
  orderSalesReportV3:
    '_product/reports/order-sales-v3' + ' ' + constants.GET_URL_PARAMS,
  returnProductReport:
    '_product/reports/return-product' + ' ' + constants.GET_URL_PARAMS,
  orderDelivered:
    '_product/manage/orders/list-delivered' + ' ' + constants.GET_URL_PARAMS,
  financeBankList: 'admin/finance-bank' + ' ' + constants.GET_URL_PARAMS,
  financeSalesReport:
    '_product/manage/orders/finance-stats' + ' ' + constants.GET_URL_PARAMS,
  updateFinanceDetails:
    '_product/manage/orders/finance-details' + ' ' + constants.PATCH_ID,
};
export const product = {
  initProduct:
    '_product/default/product-templates/init' + ' ' + constants.GET_URL_PARAMS,
  listProduct:
    '_product/default/product-templates' + ' ' + constants.GET_URL_PARAMS,
  draftCount:
    '_product/default/product-templates/draft/count' + ' ' + constants.GET,
  draftList:
    '_product/default/product-templates/draft' + ' ' + constants.GET_URL_PARAMS,
  deleteProduct:
    '_product/default/product-templates' + ' ' + constants.DELETE_ID_PARAMS,
  updateProductState:
    '_product/default/product-templates/state/variant' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  productInfo:
    '_product/default/product-templates/variant' +
    ' ' +
    constants.GET_ID_PARAMS,
  addProduct: '_product/default/product-templates' + ' ' + constants.POST_FORM,
  initProductVariant:
    '_product/default/product-templates/init/variant' +
    ' ' +
    constants.GET_ID_PARAMS,
  editProduct:
    '_product/default/product-templates' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  editProductVariant:
    '_product/default/product-templates/variant' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  deleteProductVariant:
    '_product/default/product-templates' + ' ' + constants.MULTI_PART_ID_DELETE,
};
export const manageProduct = {
  listProduct:
    '_product/manage/business-products/list' + ' ' + constants.GET_URL_PARAMS,
  updateVariantStatus:
    '_product/manage/business-products/status/variant' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  listVariant:
    '_product/manage/business-products/list-variants' +
    ' ' +
    constants.GET_URL_PARAMS,
};
export const pendingCertificates = {
  listCertificates:
    'seller/manage-business/pending/list' + ' ' + constants.GET_URL_PARAMS,
  notifyCertificates:
    'seller/manage-business/pending/notify' + ' ' + constants.POST_ID_PARAMS,
};
export const contactInquires = {
  listInquires: 'business/feedback' + ' ' + constants.GET_URL_PARAMS,
  listBuyerInquires:
    '_buyer/manage-buyers/feedback' + ' ' + constants.GET_URL_PARAMS,
};
export const buyer = {
  listBuyer: '_buyer/manage-buyers/list' + ' ' + constants.GET_URL_PARAMS,
  details: '_buyer/manage-buyers/info' + ' ' + constants.GET_ID_PARAMS,
  updateProfile: '_buyer/manage-buyers/profile' + ' ' + constants.PATCH_ID,
  addBuyer: '_buyer/manage-buyers/add' + ' ' + constants.POST_FORM,
  addAddress:
    '_buyer/manage-buyers/delivery-addresses' + ' ' + constants.POST_ID_PARAMS,
  listOnBoardedBuyers:
    '_buyer/manage-buyers/list-buyers' + ' ' + constants.GET_URL_PARAMS,
  assignTeamMember:
    '_buyer/manage-buyers/assign-member' + ' ' + constants.POST_URL_ENCODED,
  refundOrderList:
    '_buyer/manage-buyers/refunds/list' + ' ' + constants.GET_URL_PARAMS,
  refundBuyerOrderList:
    '_product/manage/orders/refunds/list' + ' ' + constants.GET_URL_PARAMS,
  refundOrderCount:
    '_product/manage/orders/refunds/orders/count' + ' ' + constants.GET,
  updateRefundStatus:
    '_product/manage/orders/refunds/status' + ' ' + constants.PATCH,
  receivablePayouts:
    '_product/manage/orders/receivable-report' + ' ' + constants.GET_URL_PARAMS,
  totalPayments:
    '_product/manage/orders/total-payments' + ' ' + constants.GET_URL_PARAMS,
  completePayouts:
    '_product/manage/orders/complete-payments' + ' ' + constants.POST_RAW,
  listOrder:
    '_product/manage/orders/buyer-list' + ' ' + constants.GET_URL_ID_PARAMS,
  dashboard:
    '_product/manage/orders/buyer-orders-report' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  cart: '_product/manage/buyers/carts' + ' ' + constants.GET_URL_ID_PARAMS,
  sendNotification:
    '_buyer/manage-buyers/send-notification' + ' ' + constants.POST_RAW,
};
export const platformFees = {
  updateCommissionFees:
    'business/config/platform/commission-fees' + ' ' + constants.PATCH,
  listOtherFees: 'business/config/platform/other-fees' + ' ' + constants.GET,
  updateOtherFees:
    'business/config/platform/other-fees' + ' ' + constants.PATCH,
};
export const advertisements = {
  addAddvertisement:
    '_product/manage/advertisements' + ' ' + constants.MULTI_PART_ID_POST,
  listAdvertisement:
    '_product/manage/advertisements/list' + ' ' + constants.GET_URL_PARAMS,
  payment:
    '_product/manage/advertisements/payment' + ' ' + constants.POST_ID_PARAMS,
  details:
    '_product/manage/advertisements/info' + ' ' + constants.GET_ID_PARAMS,
};
export const driver = {
  addDriver: '_driver/manage-drivers/add' + ' ' + constants.POST_FORM,
  listDriver: '_driver/manage-drivers/list' + ' ' + constants.GET_URL_PARAMS,
  info: '_driver/manage-drivers/info' + ' ' + constants.GET_ID_PARAMS,
  updateDriver:
    '_driver/manage-drivers/profile' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  deletDriver:
    '_driver/manage-drivers/profile' + ' ' + constants.DELETE_ID_PARAMS,
  updateStatus: '_driver/manage-drivers/status' + ' ' + constants.PATCH_ID,
  listActiveDriver:
    '_driver/manage-drivers/list-active' + ' ' + constants.GET_URL_PARAMS,
};
export const vehicle = {
  addVehicle: '_driver/manage-vehicle/add' + ' ' + constants.POST_FORM,
  listVehicle: '_driver/manage-vehicle/list' + ' ' + constants.GET_URL_PARAMS,
  info: '_driver/manage-vehicle/info' + ' ' + constants.GET_ID_PARAMS,
  updateVehicle:
    '_driver/manage-vehicle' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  updateStatus: '_driver/manage-vehicle/status' + ' ' + constants.PATCH_ID,
  updateDriver:
    '_driver/manage-vehicle/change-driver' + ' ' + constants.PATCH_ID,
  checkAssignedDriver:
    '_driver/manage-vehicle/check-assigned' + ' ' + constants.GET_ID_PARAMS,
  deleteVehicle: '_driver/manage-vehicle' + ' ' + constants.DELETE_ID_PARAMS,
};
export const customerService = {
  add: 'assist-admin' + ' ' + constants.POST_FORM,
  delete: 'assist-admin' + ' ' + constants.DELETE_ID_PARAMS,
  list: 'assist-admin' + ' ' + constants.GET_URL_PARAMS,
  status: 'assist-admin/status' + ' ' + constants.PATCH_ID,
  edit: 'assist-admin' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  monthlyTarget: 'assist-admin/monthly-target' + ' ' + constants.POST_ID_PARAMS,
  checkAssignedManager:
    'assist-admin/check-assigned' + ' ' + constants.GET_ID_PARAMS,
  removeAssignedManager:
    'assist-admin/remove-assigned' + ' ' + constants.PATCH_ID,
  assignDistrict:
    'assist-admin/assign-district' + ' ' + constants.POST_ID_PARAMS,
  myTeam: 'assist-admin/my-team' + ' ' + constants.GET_URL_ID_PARAMS,
  listDistrict: '_default/districts' + ' ' + constants.GET_URL_PARAMS,
  listArea:
    '_product/manage/orders/list-wards' + ' ' + constants.GET_URL_ID_PARAMS,
  listAssistArea:
    '_product/manage/orders/list-assist-wards' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  memberDetails:
    'assist-admin/member-details' + ' ' + constants.GET_URL_ID_PARAMS,
  districtDetails:
    'assist-admin/district-details' + ' ' + constants.GET_URL_ID_PARAMS,
  listDistrictManager:
    '_default/list-districts' + ' ' + constants.GET_URL_PARAMS,
};
export const inventory = {
  list: '_product/manage/shipments/list' + ' ' + constants.GET_URL_PARAMS,
  addCostInfo:
    '_product/manage/shipments/cost' + ' ' + constants.POST_ID_PARAMS,
  sendShipmentRequest:
    '_product/manage/shipments' + ' ' + constants.POST_ID_PARAMS,
  info: '_product/manage/shipments' + ' ' + constants.GET_ID_PARAMS,
  infoInventory:
    '_product/manage/shipments/completion-init' + ' ' + constants.GET_ID_PARAMS,
  updateStatus: '_product/manage/shipments/status' + ' ' + constants.PATCH_ID,
  listInventoryProduct:
    '_product/manage/inventory/v2/list' + ' ' + constants.GET_URL_PARAMS,
  inventoryStats:
    '_product/manage/inventory/stats' + ' ' + constants.GET_URL_PARAMS,
  listInventoryVarianTransaction:
    '_product/manage/inventory/transactions' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  variantStockList:
    '_product/manage/inventory/in-stock' + ' ' + constants.GET_ID_PARAMS,
  variantUpdateInit:
    '_product/manage/inventory/update-init' + ' ' + constants.GET_ID_PARAMS,
  variantUpdate: '_product/manage/inventory/update' + ' ' + constants.PATCH_ID,
  variantExpiredStockInfo:
    '_product/manage/inventory/expiring-stock/info' +
    ' ' +
    constants.GET_URL_PARAMS,
  variantExpiredStockList:
    '_product/manage/inventory/expiring-stock/list' +
    ' ' +
    constants.GET_URL_PARAMS,
  variantRemainder:
    '_product/manage/inventory/reminder' +
    ' ' +
    constants.POST_URL_ENCODED_ID_PARAMS,
  initReceivedDirectGoods:
    '_product/manage/inventory/batches' + ' ' + constants.GET_ID_PARAMS,
  receivedDirectGoods:
    '_product/manage/inventory/update-multiple' + ' ' + constants.PATCH_ID,
  addProductToLowStockList:
    '_product/manage/low-stock' + ' ' + constants.POST_RAW,
  lowStockList: '_product/manage/low-stock' + ' ' + constants.GET_URL_PARAMS,
  markAsOrdered: '_product/manage/low-stock' + ' ' + constants.PATCH_ID,
  lowStockReport:
    '_product/manage/low-stock/report' + ' ' + constants.GET_URL_PARAMS,
};
export const ordersDelivery = {
  init: '_product/manage/orders/init' + ' ' + constants.GET_URL_PARAMS,
  add: '_product/manage/orders' + ' ' + constants.POST_RAW,
  edit: '_product/manage/orders' + ' ' + constants.PATCH_ID,
  info: '_product/manage/orders/info' + ' ' + constants.GET_ID_PARAMS,
  list: '_product/manage/orders/list' + ' ' + constants.GET_URL_PARAMS,
  listAssistAdmin:
    '_product/manage/orders/assist-admin/list' + ' ' + constants.GET_URL_PARAMS,
  status:
    '_product/manage/orders/status' + ' ' + constants.PATCH_FORM_ID_URL_ENCODED,
  variant: '_product/manage/orders/count' + ' ' + constants.GET_URL_ID_PARAMS,
  invoiceDownload:
    '_product/reports/orders/invoice' + ' ' + constants.GET_ID_PARAMS,
  markAsDelivered:
    '_product/manage/orders/mark-as-completed' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  checkStock: '_product/manage/orders/check-stock' + ' ' + constants.POST_RAW,
  checkStatus:
    '_product/manage/orders/check-status' + ' ' + constants.GET_ID_PARAMS,
};
export const orderReturn = {
  inform:
    '_product/manage/orders/product-return' + ' ' + constants.POST_ID_PARAMS,
  list:
    '_product/manage/orders/product-return' + ' ' + constants.GET_URL_ID_PARAMS,
  stockAdjust:
    '_product/manage/orders/product-return' + ' ' + constants.PATCH_ID,
  inventoryAdjust:
    '_product/manage/orders/product-inventory-adjust' +
    ' ' +
    constants.POST_ID_PARAMS,
  markInventoryAdjust:
    '_product/manage/orders/product-inventory-adjust' +
    ' ' +
    constants.PATCH_ID,
};
export const goodsLoadingArea = {
  add: 'business/goods-loading-areas' + ' ' + constants.POST_RAW,
  list: 'business/goods-loading-areas' + ' ' + constants.GET_URL_PARAMS,
  info: 'business/goods-loading-areas' + ' ' + constants.GET_ID_PARAMS,
  update: 'business/goods-loading-areas' + ' ' + constants.PATCH_ID,
  delete: 'business/goods-loading-areas' + ' ' + constants.DELETE_ID_PARAMS,
};
export const routePlanning = {
  planSummary: '_product/manage/route-plans/summary' + ' ' + constants.POST_RAW,
  planCreate: '_product/manage/route-plans/create' + ' ' + constants.POST_RAW,
  getPlan: '_product/manage/route-plans/list' + ' ' + constants.GET_URL_PARAMS,
  planInfo: '_product/manage/route-plans/info' + ' ' + constants.GET_ID_PARAMS,
  vehicleInfo:
    '_product/manage/route-plans/vehicle/info' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  vehicleOrderVariantStatus:
    '_product/manage/route-plans/vehicle/order/variant/status' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  vehicleRouteStatus:
    '_product/manage/route-plans/vehicle/status' +
    ' ' +
    constants.PATCH_FORM_ID_URL_ENCODED,
  goodsReceivedInvoice:
    '_product/manage/route-plans/goods-received-invoices' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  ordersInvoice:
    '_product/reports/orders/invoices' + ' ' + constants.GET_URL_ID_PARAMS,
  vehicleOrderStatus:
    '_product/manage/route-plans/vehicle/order/status' +
    ' ' +
    constants.PATCH_ID,
  vehicleState:
    '_product/manage/route-plans/vehicle/state' + ' ' + constants.PATCH_ID,
  vehicleOrderSequence:
    '_product/manage/route-plans/vehicle/order' + ' ' + constants.PATCH_ID,
  vehicleOrderSubmitPartialPayment:
    '_product/manage/route-plans/vehicle/order/payment' +
    ' ' +
    constants.PATCH_ID,
  completeReview:
    '_product/manage/route-plans/complete-review' + ' ' + constants.PATCH_ID,
  addOrder:
    '_product/manage/route-plans/vehicle/order/add' + ' ' + constants.PATCH_ID,
  removeOrder:
    '_product/manage/route-plans/vehicle/order/remove' +
    ' ' +
    constants.PATCH_ID,
  updateOrder:
    '_product/manage/route-plans/vehicle/order/update' +
    ' ' +
    constants.PATCH_ID,
};
export const reports = {
  sellerPayout: '_product/reports/business-payouts' + ' ' + constants.POST_RAW,
  sellerReport:
    '_product/reports/orders-businesses' + ' ' + constants.GET_URL_PARAMS,
  customerReport:
    '_product/reports/orders-customers' + ' ' + constants.GET_URL_PARAMS,
  productsReport:
    '_product/reports/business-products' + ' ' + constants.GET_URL_PARAMS,
  productsPriceListReport:
    '_product/reports/business-products-price-list' +
    ' ' +
    constants.GET_URL_PARAMS,
  productVariantReport:
    '_product/reports/products-variants' + ' ' + constants.GET_URL_PARAMS,
  dashboardReport: '_product/reports/sales' + ' ' + constants.GET_URL_PARAMS,
  inventoryReport:
    '_product/reports/inventory' + ' ' + constants.GET_URL_PARAMS,
  customerRegistrationReport:
    '_product/reports/buyer-registration' + ' ' + constants.GET_URL_PARAMS,
  purchaseBehaviourReport:
    '_product/analytics/purchase-behaviour' + ' ' + constants.GET_URL_PARAMS,
  geographicSalesReport:
    '_product/analytics/geographic-sales-insight' +
    ' ' +
    constants.GET_URL_PARAMS,
  areaBasedCustomerReport:
    '_product/analytics/area-based-customer-segmentation' +
    ' ' +
    constants.GET_URL_PARAMS,
  frequentCustomerReport:
    '_product/analytics/frequent-customer-purchase-pattern-analysis' +
    ' ' +
    constants.GET_URL_PARAMS,
  productSalesPerformaceReport:
    '_product/analytics/product-sales-performance' +
    ' ' +
    constants.GET_URL_PARAMS,
  brandWiseSalesReport:
    '_product/analytics/brand-wise-sales-performance' +
    ' ' +
    constants.GET_URL_PARAMS,
  categorySubCategoryReport:
    '_product/analytics/category-sub-category-sales-analysis' +
    ' ' +
    constants.GET_URL_PARAMS,
  revenueGenerationReport:
    '_product/analytics/revenue-generation' + ' ' + constants.GET_URL_PARAMS,
  commissionAnalysisReport:
    '_product/analytics/commission-generation' + ' ' + constants.GET_URL_PARAMS,
  productVariantInventoryReport:
    '_product/analytics/product-variant-inventory-status' +
    ' ' +
    constants.GET_URL_PARAMS,
};
export const dashboard = {
  salesReport:
    '_product/manage/orders/sales-report2' + ' ' + constants.GET_URL_PARAMS,
  userReport:
    '_product/manage/orders/users-report' + ' ' + constants.GET_URL_PARAMS,
  orderReport:
    '_product/manage/orders/orders-report' + ' ' + constants.GET_URL_PARAMS,
  sellerReport:
    '_product/manage/orders/businesses-report' + ' ' + constants.GET_URL_PARAMS,
};
export const preOrders = {
  list: '_product/pre-orders' + ' ' + constants.GET_URL_PARAMS,
};
export const appSettings = {
  privacyPolicy: '_default/privacy-policy' + ' ' + constants.GET,
  termsCondition: '_default/terms-conditions' + ' ' + constants.GET,
  appSettings: 'config/app-settings' + ' ' + constants.GET_URL_PARAMS,
  addPrivacyPOlicy: '_default/privacy-policy' + ' ' + constants.POST_RAW,
  addTermsCondition: '_default/terms-conditions' + ' ' + constants.POST_RAW,
  addAppSettings: 'config/app-settings' + ' ' + constants.PATCH,
};
export const interTransferRequest = {
  transferRequest:
    '_product/manage/stock-transfer-request' + ' ' + constants.POST_RAW,
  listTransferRequest:
    '_product/manage/stock-transfer-request/list' +
    ' ' +
    constants.GET_URL_PARAMS,
  tranferDetail:
    '_product/manage/stock-transfer-request/info' +
    ' ' +
    constants.GET_ID_PARAMS,
  buisnessPayout:
    '_product/manage/stock-transfer-request/payouts/business-orders' +
    ' ' +
    constants.GET_URL_ID_PARAMS,
  addPayout:
    '_product/manage/stock-transfer-request/payouts/business' +
    ' ' +
    constants.POST_ID_PARAMS,
};
export const preOrderCustomer = {
  listBuyers: '_product/pre-orders/buyers' + ' ' + constants.GET_URL_PARAMS,
  listProducts: '_product/pre-orders/products' + ' ' + constants.GET_URL_PARAMS,
  listBusiness: '_product/pre-orders/business' + ' ' + constants.GET_URL_PARAMS,
};
